<template>
  <div class="commandBox">
    <div class="close-button" @click="closeCop">
      <i class="el-icon-circle-close"></i>
    </div>
    <div class="ship">{{ name }}</div>
    <div class="body">
      <div class="content">
        <el-table class="tabel-box" border height="40vh" :data="tableList" stripe style="width: 95%">
          <el-table-column prop="cnKey" label="项目" > </el-table-column>
          <el-table-column prop="cnValue" label="信息" > </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getShipInfo } from '../utils/api/index'
export default {
  props: ['name', 'hullNumber'],
  data() {
    return {
      tableList: [
        {
          cnKey: '铺设船基',
          cnValue: '2002年1月14日',
          key: 'Keel Laid',
          value: 'January 14, 2002'
        },
        {
          cnKey: '命名',
          cnValue: '2003年1月11日',
          key: 'Christened',
          value: 'January 11, 2003'
        },
        {
          cnKey: '启用',
          cnValue: '2004年9月18日',
          key: 'Commissioned',
          value: 'September 18, 2004'
        },
        {
          cnKey: '建造商',
          cnValue: 'Ingalls Shipbuilding, West Bank, Pascagoula, Miss.',
          key: 'Builder',
          value: 'Ingalls Shipbuilding, West Bank, Pascagoula, Miss.'
        },
        {
          cnKey: '推进系统',
          cnValue: '4 - LM2500 GE 船用燃气轮机',
          key: 'Propulsion system',
          value: '4 - LM2500 GE Marine Gas Turbines'
        },
        {
          cnKey: '螺旋桨',
          cnValue: '两个',
          key: 'Propellers',
          value: 'two'
        },
        {
          cnKey: '整体长度',
          cnValue: '509.5 - 513.0 英尺',
          key: 'Length, overall',
          value: '509.5 - 513.0 feet'
        },
        {cnKey: '光束',
          cnValue: '最大 66 英尺',
          key: 'Beam',
          value: 'Max 66 feet'
        },
        {
          cnKey: '吃水',
          cnValue: '31英尺',
          key: 'Draft',
          value: '31 feet'
        },
        {
          cnKey: '排量',
          cnValue: '9,192-9,217 吨',
          key: 'Displacement',
          value: '9,192-9,217 tons full load'
        },
        {
          cnKey: '航速',
          cnValue: '31节',
          key: 'Speed',
          value: '31 knots'
        },
        {
          cnKey: '飞机',
          cnValue: '两架SH-60B/R/F（LAMPS MK III）直升机',
          key: 'Aircraft',
          value: 'two SH-60B/R/F (LAMPS MK III) helicopters'
        },
        {
          cnKey: '水雷',
          cnValue: 'AN/WLD-1远程猎雷系统（RMS）',
          key: 'Mine Warfare',
          value: 'AN/WLD-1 Remote Minehunting System (RMS)'
        },
        {
          cnKey: '武器装备',
          cnValue: `- 1门MK-45 MOD 4 5"/62 口径轻型火炮
 - 1门MK-15 20mm Phalanx CIWS
 - 2座MK-38型25毫米机枪系统(MGS)
 - 2个MK-41 VLS(96个单元)，用于发射标准导弹、反潜火箭和汤姆逊导弹。
火箭（ASROC）和 "战斧 "反坦克导弹（LAM）。
 - 2座MK-32 MOD 15三联装鱼雷发射管（6枚MK 50/46 
MOD 5A鱼雷）。`,
          key: 'Armament',
          value: `
          - 1 MK-45 MOD 4 5"/62 caliber lightweight gun
 - 1 MK-15 20mm Phalanx CIWS
 - 2 MK-38 25mm Machine Gun System (MGS)
 - 2 MK-41 VLS (96 cells) for Standard Missiles, Anti-Submarine 
Rocket (ASROC) and Tomahawk ASM/LAM
 - 2 MK-32 MOD 15 triple torpedo tubes (six MK 50/46 
MOD 5A Torpedoes)
          `
        },
        {
          cnKey: '母港',
          cnValue: '夏威夷州珍珠港',
          key: 'Homeport',
          value: 'Pearl Harbor, HI.'
        },
        // {
        //   key: 'FaceBook',
        //   value: 'https://www.facebook.com/USS-CHUNG-HOON-DDG-93-122211737802543'
        // }
      ]
    };
  },
  watch: {
    hullNumber(newValue, oldValue){
      this.getShipDetail();
    }
  },
  created(){
    this.getShipDetail();
  },
  methods: {
    getShipDetail(){
      let params = {
        hullNumber: this.hullNumber.replace("-", "").replace(" ", "").toUpperCase()
      }
      getShipInfo(params)
        .then(res => {
          let data = res.data;
          if(data.resultCode == 0){
            this.tableList = data.resultData;
          }
        })
        .catch(err => {

        })
    },
    closeCop() {
      this.$emit("closeCop");
    },
  },
};
</script>

<style lang="less" scoped>
.commandBox {
  z-index: 9521;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 50vw;
  height: 50vh;
  background-color: rgba(255, 255, 255, 0.7);
  // box-shadow: -3px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray,
  //       2px 0 3px -1px gray;
  box-shadow: 5px 5px 8px 8px #888888;
  border-radius: 10px;
  .close-button {
    position: absolute;
    top: 1px;
    right: 2px;
    z-index: 9277;
    font-size: 30px;
    color: #000;
  }
  .ship{
    margin-top: 2%;
    width: 100%;
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  .body {
    width: 100%;
    height: 100%;
    position: relative;
    .content {
      .tabel-box {
        margin-top: 2%;
        margin-left: 2.5%;
        font-size: 16px;
      }
      /deep/ .cell{
        // white-space: pre-line;
      }
    }
  }
}
</style>
